import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_COWORKER,
    DELETE_COWORKER,
    COWORKER_FORM_TOOGLE_LOADING,
    SET_COWORKER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_COWORKER,
} from "../constants";
import { formatCoworkers } from "./settingsActionsUtils";

/* COWORKER LIST */
export const fetchCoworkers = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.coworkers.list)
            .then((response) => {
                const coworkers = formatCoworkers(response.data);
                dispatch({
                    type: SET_COWORKER_LIST,
                    payload: keyBy(coworkers, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// COWORKERS
export const createCoworker = (newCoworker) => {
    return async (dispatch) => {
        dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.coworkers.create, newCoworker)
            .then((response) => {
                const coworker = formatCoworkers(response.data);
                dispatch({ type: ADD_NEW_COWORKER, payload: coworker });
                dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Compañero creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCoworker = (updatedCoworker) => {
    return async (dispatch) => {
        dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.coworkers.edit}${updatedCoworker && updatedCoworker._id}`, updatedCoworker)
            .then((response) => {
                const coworker = formatCoworkers(response.data);
                dispatch({ type: UPDATE_COWORKER, payload: coworker });
                dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Compañero actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCoworkers = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.coworkers.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_COWORKER, payload: Ids });
                dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Compañero eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COWORKER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
