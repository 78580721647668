import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* INGREDIENTS */
export const getIngredients = createSelector(
    (state) => state.ingredients,
    (ingredients) => ingredients
);
export const getIngredientsForm = createSelector(
    (state) => state.ingredientsForm,
    (form) => form
);

/* MENUS */
export const getMenus = createSelector(
    (state) => state.menus,
    (menus) => menus
);
export const getMenusForm = createSelector(
    (state) => state.menusForm,
    (form) => form
);

/* PLANNERS */
export const getPlanners = createSelector(
    (state) => state.planners,
    (planners) => planners
);
export const getPlannersForm = createSelector(
    (state) => state.plannersForm,
    (form) => form
);

/* ZONES */
export const getZones = createSelector(
    (state) => state.zones,
    (zones) => zones
);
export const getZonesForm = createSelector(
    (state) => state.zonesForm,
    (form) => form
);

/* COWORKERS */
export const getCoworkers = createSelector(
    (state) => state.coworkers,
    (coworkers) => coworkers
);
export const getCoworkersForm = createSelector(
    (state) => state.coworkersForm,
    (form) => form
);
