import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MENU,
    DELETE_MENU,
    MENU_FORM_TOOGLE_LOADING,
    SET_MENU_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MENU,
} from "../constants";
import { formatMenus } from "./settingsActionsUtils";

/* MENU LIST */
export const fetchMenus = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.menus.list)
            .then((response) => {
                const menus = formatMenus(response.data);
                dispatch({
                    type: SET_MENU_LIST,
                    payload: keyBy(menus, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MENUS
export const createMenu = (newMenu) => {
    return async (dispatch) => {
        dispatch({ type: MENU_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.menus.create, newMenu)
            .then((response) => {
                const menu = formatMenus(response.data);
                dispatch({ type: ADD_NEW_MENU, payload: menu });
                dispatch({ type: MENU_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Menú creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MENU_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};


export const updateMenu = (updatedMenu, hide) => {
    return async (dispatch) => {
        dispatch({ type: MENU_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.menus.edit}${updatedMenu && updatedMenu._id}`, updatedMenu)
            .then((response) => {
                const menu = formatMenus(response.data);
                dispatch({ type: UPDATE_MENU, payload: menu });
                dispatch({ type: MENU_FORM_TOOGLE_LOADING });
                if (!hide) {
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: {
                            show: true,
                            status: "info",
                            message: "Menú actualizado.",
                        },
                    });
                }
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MENU_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMenus = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MENU_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.menus.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MENU, payload: Ids });
                dispatch({ type: MENU_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Menú eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MENU_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};