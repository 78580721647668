import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  ingredients,
  ingredientsForm,
  menus,
  menusForm,
  planners,
  plannersForm,
  zones,
  zonesForm,
  coworkers,
  coworkersForm,
} from "./Reducers";

export default combineReducers({
  coworkers,
  coworkersForm,
  zones,
  zonesForm,
  planners,
  plannersForm,
  menus,
  menusForm,
  ingredients,
  ingredientsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
