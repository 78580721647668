import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PLANNER,
    DELETE_PLANNER,
    PLANNER_FORM_TOOGLE_LOADING,
    SET_PLANNER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PLANNER,
} from "../constants";
import { formatPlanners } from "./settingsActionsUtils";

/* PLANNER LIST */
export const fetchPlanners = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.planners.list)
            .then((response) => {
                const planners = formatPlanners(response.data);
                dispatch({
                    type: SET_PLANNER_LIST,
                    payload: keyBy(planners, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PLANNERS
export const createPlanner = (newPlanner) => {
    return async (dispatch) => {
        dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.planners.create, newPlanner)
            .then((response) => {
                const planner = formatPlanners(response.data);
                dispatch({ type: ADD_NEW_PLANNER, payload: planner });
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Planificador creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePlanner = (updatedPlanner) => {
    return async (dispatch) => {
        dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.planners.edit}${updatedPlanner && updatedPlanner._id}`, updatedPlanner)
            .then((response) => {
                const planner = formatPlanners(response.data);
                dispatch({ type: UPDATE_PLANNER, payload: planner });
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Planificador actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePlanners = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.planners.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PLANNER, payload: Ids });
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Planificador eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};


export const generatePlannerAI = (date) => {
    return async (dispatch) => {
        dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.planners.ai}`, { date })
            .then((response) => {

                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const generateOnePlannerAI = (data) => {
    return async (dispatch) => {
        dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.planners.oneAI}`, data)
            .then((response) => {

                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANNER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};