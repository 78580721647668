import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ZONE,
    DELETE_ZONE,
    ZONE_FORM_TOOGLE_LOADING,
    SET_ZONE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ZONE,
} from "../constants";
import { formatZones } from "./settingsActionsUtils";

/* ZONE LIST */
export const fetchZones = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.zones.list)
            .then((response) => {
                const zones = formatZones(response.data);
                dispatch({
                    type: SET_ZONE_LIST,
                    payload: keyBy(zones, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ZONES
export const createZone = (newZone) => {
    return async (dispatch) => {
        dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.zones.create, newZone)
            .then((response) => {
                const zone = formatZones(response.data);
                dispatch({ type: ADD_NEW_ZONE, payload: zone });
                dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Zona creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateZone = (updatedZone) => {
    return async (dispatch) => {
        dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.zones.edit}${updatedZone && updatedZone._id}`, updatedZone)
            .then((response) => {
                const zone = formatZones(response.data);
                dispatch({ type: UPDATE_ZONE, payload: zone });
                dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Zona actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteZones = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.zones.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ZONE, payload: Ids });
                dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Zona eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ZONE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
