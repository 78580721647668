import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_INGREDIENT,
    DELETE_INGREDIENT,
    INGREDIENT_FORM_TOOGLE_LOADING,
    SET_INGREDIENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_INGREDIENT,
} from "../constants";
import { formatIngredients } from "./settingsActionsUtils";

/* INGREDIENT LIST */
export const fetchIngredients = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.ingredients.list)
            .then((response) => {
                const ingredients = formatIngredients(response.data);
                dispatch({
                    type: SET_INGREDIENT_LIST,
                    payload: keyBy(ingredients, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// INGREDIENTS
export const createIngredient = (newIngredient) => {
    return async (dispatch) => {
        dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.ingredients.create, newIngredient)
            .then((response) => {
                const ingredient = formatIngredients(response.data);
                dispatch({ type: ADD_NEW_INGREDIENT, payload: ingredient });
                dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Ingrediente creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateIngredient = (updatedIngredient) => {
    return async (dispatch) => {
        dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.ingredients.edit}${updatedIngredient && updatedIngredient._id}`, updatedIngredient)
            .then((response) => {
                const ingredient = formatIngredients(response.data);
                dispatch({ type: UPDATE_INGREDIENT, payload: ingredient });
                dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ingrediente actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteIngredients = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.ingredients.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_INGREDIENT, payload: Ids });
                dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ingrediente eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INGREDIENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
